<template>
  <div >


    <b-collapse
        class="card mb-0"
        animation="slide"
        v-for="(item, index) of categories"
        :key="index"
        :open="isOpen == index"
        @open="isOpen = index"
        @click.native.prevent="$emit('open', item.id)"
    >
      <template #trigger="props">
        <div
            class="card-header"
            role="button"
        >
          <p class="card-header-title">
            {{ item.label }}
          </p>
          <a v-if="item.children.length > 0" class="card-header-icon">
            <b-icon
                :icon="props.open ? 'menu-down' : 'menu-up'">
            </b-icon>
          </a>
        </div>
      </template>
      <div class="card-content" v-if="item.children.length > 0">

        <recursive-table-component @open="$emit('open', $event)" :node="item"></recursive-table-component>

      </div>
    </b-collapse>


  </div>

</template>

<script>
import Tree from "./Tree";
import RecursiveTableComponent from "@/components/deplo/components/RecursiveTableComponent";
import packages from "@/store/modules/packages.store"
import deplo from "@/components/deplo/store/deplo.store";

export default {
  name: "CategoryTableComponent",
  components: {RecursiveTableComponent, Tree },
  data () {
    return {
      isOpen: 0,
    }
  },
  computed: {
    categories() {
      return this.$store.state.deplo.categories
    }
  },
  created() {
    if(!deplo.isRegistered) {
      this.$store.registerModule('deplo', deplo)
      deplo.isRegistered = true
    }
  },
  beforeMount() {
    this.$store.dispatch("deplo/fetchAllCategories")
  },


}
</script>

<style scoped>

</style>