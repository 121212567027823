<template>
    <card-component :title="this.new ? 'Create Category': 'Edit Category'"
                    :icon="this.new ? 'plus-circle' : 'pencil'"
                    :is-scrollable="true">

      <form v-if="category" @submit.prevent="submit">
        <b-field label="Name" horizontal>
          <b-field>
            <b-input v-model="category.label" name="name" required />
          </b-field>
        </b-field>
        <b-field label="Hierarchy" horizontal>
          <nav class="breadcrumb has-arrow-separator">
            <ul>
              <li v-for="(title,index) in category.breadcrumb" :key="index"><a>{{ title }}</a></li>
              <li class="is-active"><a>{{ category.label }}</a> </li>
            </ul>
          </nav>

        </b-field>
        <b-field label="Parent category" horizontal>
          <treeselect v-model="category.parent_id"
                      :multiple="false"
                      :show-count="true"
                      :options="categories" />
        </b-field>
        <b-field label="Icon" horizontal>
          <b-field message="Enter the name string of a mdi icon">
            <b-input v-model="category.icon" name="icon" placeholder="E.g. eye" required />
          </b-field>
        </b-field>
        <b-field label="Color" horizontal>
          <b-field message="Enter the name string of a bulma color preset">
            <b-input v-model="category.color" name="name" placeholder="E.g. is-danger" required />
          </b-field>
        </b-field>

        <hr>
        <b-field horizontal >
          <b-field grouped position="is-right">
            <div class="control">
              <b-button type="is-primary is-outlined">Cancel</b-button>
            </div>
            <div class="control">
              <b-button native-type="submit" type="is-primary">{{ this.new ? "Create" : "Update" }}</b-button>
            </div>
          </b-field>

        </b-field>

      </form>

    </card-component>


</template>

<script>
import CardComponent from "@/components/CardComponent";
import Treeselect from "@riophae/vue-treeselect";
import '@riophae/vue-treeselect/dist/vue-treeselect.css'


export default {
  name: "CategoryDetailComponent",
  components: { CardComponent, Treeselect },
  props: {
    id: {
      type: Number
    },
    new: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      category: null,
    }
  },
  computed: {
    categories() {
      return this.$store.state.deplo.categories
    }
  },
  watch: {
    id() {
      this.$store.dispatch("deplo/fetchCategory", this.id).then(response => {
        this.category = response.data.data;
      })
    }
  },
  mounted() {
    if (this.new) {
      this.category = {
        name: "",
        parent_id: null,

      }
    } else {
      this.$store.dispatch("deplo/fetchCategory", this.id).then(response => {
        this.category = response.data.data;
      })
    }
  },
  methods: {
    submit() {
      this.category.name = this.category.label
      this.category.type = "hardcoded"
      if(!this.category.parent_id) {
        this.category.parent_id = null
      }
      if (this.new) {
        this.$store.dispatch('deplo/createCategory', this.category)
            .then(response => {
              //this.$parent.close()
              this.$buefy.toast.open({
                message: 'Category created',
                type: 'is-success'
              })
              this.$parent.close()
              this.$store.dispatch("deplo/fetchAllCategories");

            }).catch(err => {
          this.$buefy.toast.open({
            message: err,
            type: 'is-danger'
          })
        })
      }
      // Update existing category
      else {
        this.$store.dispatch('deplo/updateCategory', this.category)
            .then(response => {
              //this.$parent.close()
              this.category = response.data.data
              this.$buefy.toast.open({
                message: 'Category updated',
                type: 'is-success'
              })
              this.$store.dispatch("deplo/fetchAllCategories");

            }).catch(err => {
          this.$buefy.toast.open({
            message: err,
            type: 'is-danger'
          })
        })
      }

    },
  }

}
</script>

<style scoped>

</style>