<template>
  <section class="section is-main-section">

    <title-bar :title-stack="titleStack"/>

    <div class="columns">

      <div class="column is-one-third">
        <card-component icon="eye" title="Categories" class="has-table" :has-button-slot="true">
          <b-button slot="button" size="is-small" icon-left="plus-circle" @click="newCategory">Add</b-button>
          <category-table-component @open="handleOpen"></category-table-component>
        </card-component>
      </div>

      <div class="column">
          <category-detail-component v-if="id" :id="id"></category-detail-component>
          <template v-else>
            <card-component title="Details">
              <div class="card-content">
                <small>Select a category in the sidebar</small>
              </div>
            </card-component>
          </template>
      </div>

    </div>

  </section>
</template>

<script>
import CardComponent from '@/components/CardComponent'
import TitleBar from "@/components/TitleBar";
import CategoryTableComponent from "@/components/deplo/components/CategoryTableComponent";
import CategoryDetailComponent from "@/components/deplo/components/CategoryDetailComponent";
import NasFormComponent from "@/components/devices/nas/NasFormComponent";

export default {
  name: 'Categories.vue',
  components: {CategoryDetailComponent, CategoryTableComponent, TitleBar, CardComponent },
  computed: {
    titleStack () {
      return [
        'Deplo',
        'Categories'
      ]
    },

  },
  data() {
    return {
      id: null
    }
  },

  methods: {
    handleOpen(e) {
      this.id = e
    },
    newCategory() {
      this.$buefy.modal.open({
        parent: this,
        component: CategoryDetailComponent,
        props: {
          id: null,
          new: true,
        },
        hasModalCard: true,
        trapFocus: true
      })

    }
  }
}
</script>
