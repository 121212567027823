<template>
  <div class="ml-3 mr-2 py-2">

    <b-collapse
        class="card"
        animation="slide"
        v-for="(item, index) of  node.children"
        :key="item.id"
        :open="isOpen == index"
        @open="isOpen = index"
        @click.native.stop="$emit('open', item.id)"
    >
      <template #trigger="props">
        <div
            class="card-header"
            role="button"
        >
          <p class="card-header-title">
            {{ item.label }}
          </p>
          <a v-if="item.children.length > 0" class="card-header-icon">
            <b-icon
                :icon="props.open ? 'menu-down' : 'menu-up'">
            </b-icon>
          </a>
        </div>
      </template>

      <div class="card-content" v-if="item.children.length > 0">

        <recursive-table-component @open="$emit('open', $event)" :node="item"></recursive-table-component>

      </div>

    </b-collapse>
  </div>


  <!--  <li class="node-tree">-->
  <!--    <span class="label">{{ node.label }}</span>-->
  <!--    <ul v-if="node.children && node.children.length">-->
  <!--      <recursive-table-component v-for="child in node.children" :node="child"></recursive-table-component>-->
  <!--    </ul>-->
  <!--  </li>-->

</template>

<script>
export default {
  name: "RecursiveTableComponent",
  props: {
    node: Object
  },
  data() {
    return {
      isOpen: 0,

    }
  }
};
</script>